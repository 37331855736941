import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    async readAllBytes(file: File | null): Promise<string> {
        if (!file) { return Promise.resolve(''); }

        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onloadend = _ => {
                if (reader.result) {
                    resolve(reader.result as string);
                } else {
                    reject(reader.error);
                }
            };
        });
    }
}
