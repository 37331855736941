<!-- Google Tag Manager (noscript) -->
<noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K895G2Q" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->
<sk-banner-container></sk-banner-container>
<header sk-header></header>
<section class="sk-flex-page">
    <nav sk-platform-navigation>
    </nav>
    <router-outlet></router-outlet>
</section>
<sk-overlay></sk-overlay>