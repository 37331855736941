import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Page, SkykickPlatformPage, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular';
import { BetaFlag } from '../../core/infrastructure/beta-flags';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'main[sk-dashboard]',
    templateUrl: './whitelabel.component.html',
    styleUrls: ['./whitelabel.component.scss']
})
export class WhiteLabelComponent extends SkykickPlatformPage {
    constructor(
        activatedRoute: ActivatedRoute,
        taskManagerService: TaskManagerService,
        readonly userService: AbstractUserProvider,
        readonly translateService: TranslateService
    ) {
        super(activatedRoute, taskManagerService);

        // TODO: Put hiding and showing SkyKickProductPages into a service.
        this.Pages.forEach((page, index) => {
            page.Name = translateService.instant(page.Name) as string;
            this.hideMarketplaceProductPage(page, index, userService);
        });

        // Remarks: Cannot splice an array twice in the same foreach above.
        this.Pages.forEach((page, index) => {
            page.Name = translateService.instant(page.Name) as string;
        });
    }

    /**
     * Hides the marketplace page if the user does not have Marketplace Syndication permission.
     * @param page The Page to hide.
     * @param index The index of the page to hide.
     * @param userService The service to use when checking for Marketplace Syndication permission
     */
    private hideMarketplaceProductPage(page: Page, index: number, userService: AbstractUserProvider): void {
        if (page.Route !== 'marketplace') { return; }

        const currentUser: ISkyKickPartnerPortalUser = userService.getCurrentUser();
        if (currentUser.hasPermission(BetaFlag.MarketplaceSyndication)) { return; }

        // TODO: Make the marketplace page disabled instead of removing it entirely.
        this.Pages.splice(index, 1);
    }
}
