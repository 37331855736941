import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { InitializationService } from "../login/initialization.service";

@Injectable({
    providedIn: 'root'
})
export class HasBackupPermissionGuard implements CanActivate {
  constructor(
      private abstractUserProvider: AbstractUserProvider,
      private initializationService: InitializationService,
      @Inject('Window') private window: Window
  ) { }

  canActivate(routeSnapshot: ActivatedRouteSnapshot): boolean {
    const user = this.abstractUserProvider.getCurrentUser();
    const hasPermission = user.hasPermission('Cloud_Backup_Full_Access');
    if (!hasPermission) {
      this.window.location.href = `${this.initializationService.getLoginRedirect()}/error`;
      return false;
    }
    return true;
  }
}