import { Injectable } from '@angular/core';
import { BrandSettings } from '../../../shared/BrandSettings';
import { BrandSettingsRequest } from '../../../shared/models/BrandSettingsRequest';
import { FileUploadRequest } from '../../../shared/models/FileUploadRequest';
import { FileUploadResponse } from '../../../shared/models/FileUploadResponse';
import { WhiteLabelDataService } from '../services/whitelabel-data.service';

@Injectable({
    providedIn: 'root'
})
export class BrandSettingsService {

    constructor(private readonly whiteLabelDataService: WhiteLabelDataService) { }
    /**
     * Retrieves brand settings
     */
    public async getBrandSettings(): Promise<BrandSettings | null> {
        return this.whiteLabelDataService.getBrandSettings().toPromise();
    }

    /**
     * Saves brand settings
     */
    public async saveBrandSettings(brandSettings: BrandSettingsRequest): Promise<BrandSettings | null> {
        return this.whiteLabelDataService.saveBrandSettings(brandSettings).toPromise();
    }

    /**
     * Upload favicon
     */
    public async uploadFavicon(fileUploadRequest: FileUploadRequest): Promise<FileUploadResponse | null> {
        return this.whiteLabelDataService.uploadFileToBlob(fileUploadRequest).toPromise();
    }
}
