import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { BrandSettings } from '../../../shared/BrandSettings';
import { FileUploadRequest } from '../../../shared/models/FileUploadRequest';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { BrandSettingsService } from './settings.service';
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'main[sk-dashboard]',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends SkyKickProductPage implements OnInit, AfterViewInit {
    /**
     * The name of the component.
     */
    public static readonly ComponentName: string = 'settings';
    /**
     * The maximum allowed file size for favicon in Bytes.
     */
    private readonly _skMaxFaviconSize: number = 300000;
    /**
     * Form group for brand settings
     *
     */
    skFaviconUrl!: SafeUrl;
    @Input() skBrandAssetsForm!: FormGroup;

    /**
     * Indicates if system is busy
     */
    public isLoading: boolean;
    /**
     * Custom validation message
     */
    public customValidationMessage!: string;

    /**
     * Filename to display when previewing a saved favicon
     */
    public previewFaviconFileName!: string | null;

    /**
     * Favicon binary file
     */
    public faviconFile: File | null;

    constructor(
        taskManagerService: TaskManagerService,
        private readonly formBuilder: FormBuilder,
        private readonly sanitizer: DomSanitizer,
        private readonly fileUploadService: FileUploadService,
        private readonly brandSettingsService: BrandSettingsService,
        private readonly toastrService: ToastrService,
        private readonly translateService: TranslateService) {
        super(taskManagerService);
    }

    ngOnInit(): void {
        this.skBrandAssetsForm = this.formBuilder.group({
            skCompanyName: ['', Validators.required],
            skPageFavicon: ['']
        });

    }

    async ngAfterViewInit(): Promise<void> {
        try {
            const brandSettings = await this.brandSettingsService.getBrandSettings();
            if (!brandSettings) {
                return;
            }

            this.skBrandAssetsForm.patchValue({ skCompanyName: brandSettings.companyName, skFaviconUrl: brandSettings.faviconLogoUri });
            if (brandSettings.faviconLogoUri) {
                this.skFaviconUrl = brandSettings.faviconLogoUri;
                this.previewFaviconFileName = 'favicon.ico';
            }
        } catch (err) {
            console.error('Unable to fetch brand assets');
        }
    }

    get pageFaviconFileName(): string | null {
        const favicon = this.skBrandAssetsForm.get('skPageFavicon') as FormControl | null;
        if (!favicon) { return null; }
        const faviconFile = favicon.value as File;
        return faviconFile.name;
    }

    public pageFaviconFileChange(selectedFiles: FileList | null | undefined): void {
        if (!selectedFiles || selectedFiles.length <= 0) { return; }
        const selectedFile: File | null = selectedFiles.item(0);
        if (!selectedFile || selectedFile.size > this._skMaxFaviconSize) {
            (this.skBrandAssetsForm as FormGroup)
                .setErrors({ incorrect: true });
            this.customValidationMessage = 'ERRORS.FILE_LIMIT';
            return;
        }

        if (!this.skBrandAssetsForm) { return; }
        // preview favicon
        const mimeType = selectedFile.type;
        if (mimeType.match(/image\/*/) === null) {
            (this.skBrandAssetsForm as FormGroup)
                .setErrors({ incorrect: true });
            this.customValidationMessage = 'ERRORS.FILE_TYPE';
            return;
        }

        const reader = new FileReader();
        reader.onload = _event => {
            this.skFaviconUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        };
        reader.readAsDataURL(selectedFile);
        this.faviconFile = selectedFile;
    }

    public async onSubmit(): Promise<void> {
        this.isLoading = true;
        const faviconFile = this.faviconFile;
        const companyName = this.skBrandAssetsForm.get('skCompanyName') as FormControl | null;
        if (!companyName) {
            return;
        }
        let faviconUrl: string | null = '';
        if (faviconFile) {
            const faviconRawData = btoa(await this.fileUploadService.readAllBytes(faviconFile));
            const fileUploadRequest: FileUploadRequest = {
                fileData: faviconRawData,
                fileName: faviconFile.name,
                fileType: 'favicon'
            };
            try {
                const fileUploadResponse = await this.brandSettingsService.uploadFavicon(fileUploadRequest);
                if (!fileUploadResponse) {
                    throw new Error('No response from file upload request');
                }
                faviconUrl = fileUploadResponse.fileUri;
            } catch {
                console.log('Unable to upload favicon');
                (this.skBrandAssetsForm as FormGroup)
                    .setErrors({ incorrect: true });
                this.customValidationMessage = 'ERRORS.FAVICON_FAILURE';
                this.isLoading = false;
                return;
            }
        }

        const brandSettings: BrandSettings = {
            companyName: companyName.value,
            faviconLogoUri: faviconUrl || '',
            companyLogoUri: ''
        };
        try {
            await this.brandSettingsService.saveBrandSettings(brandSettings);
            const successTitle = await this.translateService.get('COMMON.SUCCESS').toPromise<string>();
            const successMessage = await this.translateService.get('COMPONENTS.BRAND_ASSETS.SAVED_SETTINGS').toPromise<string>();
            this.toastrService.success(successMessage, successTitle);
        } catch {
            (this.skBrandAssetsForm as FormGroup)
                .setErrors({ incorrect: true });
            console.log('Unable to save brand settings');
            this.customValidationMessage = 'ERRORS.BRANDSETTINGS_SAVE';
        }
        finally {
            this.isLoading = false;
        }
    }
}
