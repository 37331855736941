// tslint:disable: file-name-casing
/**
 * Enumeration for the grant access permissions
 */
export enum GrantAccessPermissions {
    /**
     * No access is given
     */
    NoAccess = 0,
    /**
     * search and restore access is given
     */
    SearchAndRestore = 1,
    /**
     * full access is given
     */
    FullAccess = 2
}
