<div class="modal-header">
    <h4 class="modal-title">{{Title}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss(false)">
        <span aria-hidden="true">
            <i class="material-icons md-22">close</i>
        </span>
        <span class="sr-only">{{'COMMON.CLOSE' | translate}}}</span>
    </button>
</div>
<div class="modal-body">
    {{Message}}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary"
        (click)="modal.close(true)">{{'COMPONENTS.PUBLISH.UNPUBLISH_LINK' | translate}}</button>
    <button type="button" class="btn btn-secondary"
        (click)="modal.close(false)">{{'COMMON.CANCEL' | translate}}</button>
</div>
