import { Injectable } from '@angular/core';
import { Certificate } from '../../../shared/models/Certificate';
import { GrantAccessPermissions } from '../../../shared/models/GrantAccessPermissions';
import { MarketplaceConfigPartnerSettingsRequest } from '../../../shared/models/MarketplaceConfigPartnerSettingsRequest';
import { SupportDetails } from '../../../shared/models/SupportDetails';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { WhiteLabelDataService } from '../services/whitelabel-data.service';
import { CustomUrlSelection } from '../shared/custom-url/custom-url.component';
import { SupportOption } from '../shared/customer-support/customer-support.component';
import { MarketplaceConfigFormModel } from './marketplace-config-form.model';

@Injectable({
    providedIn: 'root',
    deps: [FileUploadService]
})
export class MarketplaceConfigService {

    constructor(
        private readonly whiteLabelDataService: WhiteLabelDataService,
        private readonly fileUploadService: FileUploadService
    ) { }

    public async getMarketplaceConfigWhiteLabelSettings(): Promise<MarketplaceConfigFormModel | null> {
        const marketplaceConfigSettings = await this.whiteLabelDataService.getMarketplaceConfigSettings().toPromise();
        if (!marketplaceConfigSettings) { return null; }

        // Build CustomUrl section of the Form model.
        const pageUrl: string = marketplaceConfigSettings.pageUrl || '';
        //
        // We limit the range of custom subdomain to 50. Because Azure has a limit of 89 characters for a domain name.
        // Therefore after .configureyourorder.com has been appended we only have 64 characters available.
        //
        const skCustomSubDomainRegex: RegExp = /^(https:\/\/)([a-zA-Z0-9-]{3,50})\.configureyourorder\.com\/?$/;
        const skCustomSubDomainNameRegexGroupIndex = 2;
        const subDomainUrlMatches: RegExpMatchArray | null = pageUrl.match(skCustomSubDomainRegex);
        const skCustomUrl = {
            skCustomUrlSelection: (subDomainUrlMatches ? 'subdomain' : 'domain') as CustomUrlSelection,
            skDomainName: subDomainUrlMatches ? '' : pageUrl,
            skSubDomainName: subDomainUrlMatches ? subDomainUrlMatches[skCustomSubDomainNameRegexGroupIndex] : '',
            skCertificate: marketplaceConfigSettings.domainCertificate || null,
            skSelectedSslCert: null,
            skSslPassphrase: ''
        };

        // Build CustomerSupport section of the Form model.
        const supportDetails: SupportDetails | undefined = marketplaceConfigSettings.supportDetails;
        const supportOption: SupportOption = marketplaceConfigSettings.supportUrl ? 'support-url' : 'support-details';
        const skCustomerSupport = {
            skSupportOptions: supportOption,
            skSupportEmail: supportDetails ? supportDetails.emailAddress : '',
            skSupportPhone: supportDetails ? supportDetails.phoneNumber : '',
            skSupportHours: supportDetails ? supportDetails.hours : '',
            skSupportUrl: marketplaceConfigSettings.supportUrl || ''
        };

        /**
         * TODO: Revisit GrantAccessPermissions to account for all 3 options.
         * Currently we only handle FullAccess or NoAccess but we should support all the options.
         */
        const grantFullAccess: boolean = marketplaceConfigSettings.grantAccessPermissions === GrantAccessPermissions.FullAccess;

        // Put together the form model.
        const formModel: MarketplaceConfigFormModel = {
            skPageTitle: marketplaceConfigSettings.pageTitle || '',
            skCustomUrl,
            skCustomerSupport,
            skIsEnabled: marketplaceConfigSettings.isEnabled || false,
            skGrantAccessPermission: grantFullAccess
        };

        return formModel;
    }

    public async saveMarketplaceConfigWhiteLabelSettings(
        newSettings: MarketplaceConfigFormModel,
        publish: boolean
    ): Promise<Certificate | null> {
        // Determine the pageUrl
        const isSubDomainOption = newSettings.skCustomUrl.skCustomUrlSelection === 'subdomain';
        const subDomainUrl = `https://${newSettings.skCustomUrl.skSubDomainName}.configureyourorder.com/`;
        const domainUrl = newSettings.skCustomUrl.skDomainName;
        const pageUrl: string = isSubDomainOption ? subDomainUrl : domainUrl;

        // Determine the certificate
        const certData: string | undefined =
            isSubDomainOption ? undefined : btoa(await this.fileUploadService.readAllBytes(newSettings.skCustomUrl.skSelectedSslCert));
        const domainCertificate: Certificate | undefined =
            isSubDomainOption ? undefined : { passphrase: newSettings.skCustomUrl.skSslPassphrase };

        // Determine the Support Option
        const isSupportUrlOption: boolean = newSettings.skCustomerSupport.skSupportOptions === 'support-url';
        const supportDetails: SupportDetails | undefined =
            isSupportUrlOption ? undefined : {
                emailAddress: newSettings.skCustomerSupport.skSupportEmail,
                phoneNumber: newSettings.skCustomerSupport.skSupportPhone,
                hours: newSettings.skCustomerSupport.skSupportHours
            };
        const supportUrl: string | undefined = isSupportUrlOption ? newSettings.skCustomerSupport.skSupportUrl : undefined;

        // Determine the access permissions
        const grantAccessPermissions = newSettings.skGrantAccessPermission ?
            GrantAccessPermissions.FullAccess : GrantAccessPermissions.NoAccess;

        // Build the request object
        const marketplaceConfigPartnerSettingsRequest: MarketplaceConfigPartnerSettingsRequest = {
            certData,
            marketplaceConfigSettings: {
                domainCertificate,
                isEnabled: publish,
                pageTitle: newSettings.skPageTitle,
                pageUrl,
                supportDetails,
                supportUrl,
                grantAccessPermissions
            }
        };

        return this.whiteLabelDataService.saveMarketplaceConfigSettings(marketplaceConfigPartnerSettingsRequest).toPromise();
    }
}
