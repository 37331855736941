import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService } from '@skykick/core';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'sk-publish',
    templateUrl: './publish.component.html',
    styleUrls: ['./publish.component.scss']
})
export class PublishComponent {
    constructor(
        private readonly modalService: SkyKickModalService,
        private readonly translateService: TranslateService
    ) { }
    get skStatus(): 'draft' | 'published' {
        return !this.skPublished ? 'draft' : 'published';
    }
    get skVisibility(): 'private' | 'public' {
        return !this.skVisibility ? 'private' : 'public';
    }
    @Input() skFirstSave: boolean | undefined;
    @Input() skPublished: boolean | undefined;
    @Output() readonly publish = new EventEmitter<void>();
    @Output() readonly preview = new EventEmitter<void>();
    @Output() readonly unpublish = new EventEmitter<void>();

    public async confirmUnpublish(): Promise<void> {
        const modalResult = this.modalService.open<ConfirmationModalComponent, boolean>(ConfirmationModalComponent, { size: 'lg' });
        modalResult.componentInstance.Title = await this.translateService
            .get('COMPONENTS.PUBLISH.UNPUBLISH_CONFIRMATION_TITLE')
            .toPromise<string>();
        modalResult.componentInstance.Message = await this.translateService
            .get('COMPONENTS.PUBLISH.UNPUBLISH_CONFIRMATION_BODY')
            .toPromise<string>();

        const unpublish = (await modalResult.result).data;

        if (unpublish) {
            this.unpublish.emit();
        }
    }
}
