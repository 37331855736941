import { Injectable } from '@angular/core';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular/lib/user-provider/iskykick-partner-portal-user';
import { TracingConfiguration } from 'environments/tracing-configuration';

@Injectable({
    providedIn: 'root'
})
export class WhiteLabelTracingService {

    private isInitialized = false;

    /**
     * Bootstraps the DataDog RUM client
     */
    public initializeWhiteLabelTracing(config: TracingConfiguration): void {

        if (!this.isEnabled(config))
            return;

        if (!this.isValid(config)) {
            console.error("Tracing configuration is not valid");
            return;
        }

        config.defaultPrivacyView = this.getValidPrivacyType(config.defaultPrivacyView);

        this.initialize(config);
    }

    private initialize(config: TracingConfiguration): void {

        if (this.isInitialized) {
            console.error("Tracing service is being initialized multiple times");
            return;
        }

        datadogRum.init({
            applicationId: config.applicationId,
            clientToken: config.clientToken,
            site: 'datadoghq.com',
            service: config.serviceName,
            sessionSampleRate: config.sessionSampleRate,
            sessionReplaySampleRate: config.sessionReplaySampleRate,
            trackUserInteractions: config.trackUserInteractions,
            trackResources: config.trackResources,
            trackLongTasks: config.trackLongTasks,
            defaultPrivacyLevel: <DefaultPrivacyLevel>config.defaultPrivacyView,
            env: config.environment
        });

        if (config.sessionReplaySampleRate > 0) {
            datadogRum.startSessionReplayRecording();
        }

        this.isInitialized = true;
    }

    public setUserData(user: ISkyKickPartnerPortalUser): void {
        if (this.isInitialized) {
            datadogRum.setUser({
                id: user.userId,
                email: user.email
            });
            datadogRum.setUserProperty("partnerId", user.partnerId);
        }
    }

    private isEnabled(config: TracingConfiguration): boolean {
        return config?.enabled && config.sessionSampleRate > 0;
    }

    private isValid(config: TracingConfiguration): boolean {
        return !!config.applicationId && !!config.clientToken;
    }

    private getValidPrivacyType(defaultPrivacyView: string): DefaultPrivacyLevel {

        const dataDogPrivacyViewTypes: string[] = [
            DefaultPrivacyLevel.ALLOW,
            DefaultPrivacyLevel.MASK,
            DefaultPrivacyLevel.MASK_USER_INPUT
        ];

        if (defaultPrivacyView?.length > 0) {
            if (dataDogPrivacyViewTypes.find(x => x == defaultPrivacyView)) {
                return <DefaultPrivacyLevel>defaultPrivacyView;
            }
        }

        // Mask everything by default.
        return DefaultPrivacyLevel.MASK;
    }
}
