import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ApplicationRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { WelcomeModalComponent } from './utility/new-experience-modal/welcome-modal/welcome-modal.component';
import { WhiteLabelModule } from './syndication/whitelabel/whitelabel.module';

import { IntercomModule } from 'ng-intercom';
import { HttpLoaderFactory, LanguageLoader } from './core/i18n/translation-loader-factory';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

/**
 *
 */
@NgModule({
    declarations: [
        AppComponent,
        WelcomeModalComponent
    ],
    imports: [
        IntercomModule.forRoot({
            appId: 'itg40p5r',
            updateOnRouterChange: true,
            /**
             * Customize horizontal padding
             */
            horizontal_padding: 20,
            /**
             * Customize vertical padding
             */
            vertical_padding: 20,
        }),
        // @angular modules
        BrowserModule,
        BrowserAnimationsModule,
        // application modules
        CoreModule,
        SharedModule,
        WhiteLabelModule,
        // module routing
        RouterModule.forRoot(ApplicationRoutes, {
            enableTracing: false, paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: APP_INITIALIZER, useFactory: LanguageLoader, multi: true, deps: [TranslateService, CookieService] },
    ]
})
export class AppModule { }
