<form [formGroup]="skCustomUrl">
    <div class="row px-0 mx-0">
        <fieldset class="col-12 form-group p-0">
            <!-- Component Header -->
            <h4 class="card-title">
                <span class="font-strong" translate="COMPONENTS.CUSTOM_URL.TITLE"></span>
            </h4>

            <!-- SubDomain Option -->
            <div class="custom-control custom-radio sk-radio">
                <input class="custom-control-input" id="skWhiteLabelCustomDomainCreateSubDomainOptionInput"
                    value="subdomain" formControlName="skCustomUrlSelection" name="skCustomUrlSelection"
                    [checked]="isSubDomainOption" type="radio">
                <label class="custom-control-label" for="skWhiteLabelCustomDomainCreateSubDomainOptionInput"
                    translate="COMPONENTS.CUSTOM_URL.SUBDOMAIN"></label>
                <div *ngIf="isSubDomainOption">
                    <label class="sk-form-text" translate="COMPONENTS.CUSTOM_URL.SUBDOMAIN_HINT"></label>
                    <fieldset class="my-4">
                        <label class="mr-1 font-medium">https://</label>
                        <div class="d-inline-block">
                            <input class="form-control" id="skWhiteLabelCustomDomainCreateSubDomainNameInput"
                                formControlName="skSubDomainName" name="skSubDomainName" [required]="isSubDomainOption"
                                type="text">
                        </div>
                        <label class="ml-1 font-medium">{{skSubdomainHostName}}</label>
                    </fieldset>
                </div>
            </div>

            <!-- Domain Option -->
            <div class="custom-control custom-radio sk-radio col-lg-12 col-xl-8">
                <input class="custom-control-input" id="skWhiteLabelCustomDomainCreateDomainOptionInput" value="domain"
                    formControlName="skCustomUrlSelection" name="skCustomUrlSelection" [checked]="!isSubDomainOption"
                    type="radio">
                <label class="custom-control-label" for="skWhiteLabelCustomDomainCreateDomainOptionInput"
                    translate="COMPONENTS.CUSTOM_URL.DOMAIN"></label>
                <div *ngIf="!isSubDomainOption">
                    <label class="sk-form-text">
                        {{'COMPONENTS.CUSTOM_URL.DOMAIN_HINT' | translate: { skSubdomainHostName: skSubdomainHostName } }}
                    </label>
                    <fieldset class="form-group my-4">
                        <label class="font-medium align-middle" translate="COMPONENTS.CUSTOM_URL.DOMAIN_HEADER"></label>
                        <input class="form-control" id="skWhiteLabelCustomDomainCreateDomainNameInput"
                            formControlName="skDomainName" name="skDomainName" placeholder="http://"
                            [required]="!isSubDomainOption" type="text">
                        <small class="sk-form-text text-muted" translate="COMPONENTS.CUSTOM_URL.DOMAIN_EXAMPLE"></small>
                    </fieldset>
                    <fieldset class="form-group my-4 mw-25">
                        <div class="list-group">
                            <div class="list-group-item d-flex align-items-center">
                                <h4 class="font-strong" translate="COMPONENTS.CUSTOM_URL.DOMAIN_SSL_CERT"></h4>
                                <button type="button" (click)="skEditSslCertificate = true" class="btn btn-link ml-auto"
                                    [hidden]="!hasCertificate || skEditSslCertificate">{{'COMMON.EDIT' | translate}}</button>
                                <button type="button"
                                    (click)="skEditSslCertificate = false; skSslPassphrase = null; skSelectedSslCert = null; skSelectedSslCertInput.value = null"
                                    class="btn btn-link ml-auto"
                                    [hidden]="!hasCertificate || !skEditSslCertificate">{{'COMMON.CANCEL' | translate}}</button>
                            </div>
                            <!-- Show Upload Domain Certificate UI -->
                            <div class="list-group-item" [hidden]="hasCertificate && !skEditSslCertificate">
                                <label class="font-medium" translate="COMPONENTS.CUSTOM_URL.DOMAIN_SSL_CERT"></label>
                                <div class="custom-file">
                                    <input #skSelectedSslCertInput
                                        (change)="sslCertificateFileChanged($event.target.files)"
                                        id="skWhiteLabelCustomUrlSslCertificateFileInput" class="custom-file-input"
                                        type="file">
                                    <label class="custom-file-label" for="skWhiteLabelCustomUrlSslCertificate">
                                        <div class="custom-file-name text-truncate">
                                            {{sslCertFileName || 'COMMON.CHOOSE_FILE' | translate}}
                                        </div>
                                    </label>
                                </div>
                                <label class="font-medium mt-3"
                                    translate="COMPONENTS.CUSTOM_URL.DOMAIN_ENTER_PASSPHRASE"></label>
                                <div class="form-group sk-input-icon">
                                    <div class="sk-input-group">
                                        <input id="skWhiteLabelCustomUrlSslCertificatePassphraseInput"
                                            class="form-control" formControlName="skSslPassphrase"
                                            name="skSslPassphrase" [type]="sslCertShowPassphrase ? 'text' : 'password'"
                                            placeholder="">
                                        <button class="btn sk-btn-icon-basic sk-password-toggle" type="button">
                                            <i class="material-icons md-18 sk-password-show"
                                                (click)="sslCertShowPassphrase = !sslCertShowPassphrase">
                                                {{sslCertShowPassphrase ? 'visibility_off' : 'visibility'}}
                                            </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- Show Domain Certificate Info UI -->
                            <div class="list-group-item" [hidden]="!hasCertificate || skEditSslCertificate">
                                <div class="mb-2">
                                    <label class="font-medium"
                                        translate="COMPONENTS.CUSTOM_URL.DOMAIN_SSL_CERT_DETAILS"></label>
                                </div>
                                <div class="mb-2">
                                    <label class="d-block mb-0 font-medium"
                                        translate="COMPONENTS.CUSTOM_URL.DOMAIN_SSL_CERT_NAME"></label>
                                    <label class="d-block">{{skCertificate?.name}}</label>
                                </div>
                                <div class="mb-2">
                                    <label class="d-block mb-0 font-medium"
                                        translate="COMPONENTS.CUSTOM_URL.DOMAIN_SSL_CERT_FINGERPRINT"></label>
                                    <label class="d-block">{{skCertificate?.thumbprint}}</label>
                                </div>
                                <div class="mb-2">
                                    <label class="d-block mb-0 font-medium"
                                        translate="COMPONENTS.CUSTOM_URL.DOMAIN_SSL_CERT_EXPIRATION"></label>
                                    <label class="d-block">{{skCertificate?.expirationDate}}</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </fieldset>
    </div>
</form>
