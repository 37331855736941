import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

/**
 * Automatically intercepts http requests and routes them through the sewr system.
 * @remarks Sewr system can be bypassed by adding the SEWRInterceptor.bypassSewrProxy property to a request headers.
 */
@Injectable({
    providedIn: 'root'
})
export class SEWRInterceptor implements HttpInterceptor {
    /** The property to add to the http header in order to prevent proxying to the sewr system. */
    public static readonly BypassSewrProxy = 'X-Bypass-Sewr';
    /** RegExp(s) used for bypassing the sewr sytem when requesting application assets over http. */
    private readonly IgnoreAssets: RegExp = /^\/assets/;
    /**
     * Ignoring absolute URLs as other links from common components / packages
     * could have absolute links and we don't want those links to go through SEWR
     */
    private readonly IgnoreAbsoluteURL: RegExp = /^https?:\/\//;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const bypassSewr: boolean = request.headers.has(SEWRInterceptor.BypassSewrProxy);
        if (this.IgnoreAssets.test(request.url) || bypassSewr || this.IgnoreAbsoluteURL.test(request.url)) {
            const sewrBypassedRequest = request.clone({
                headers: request.headers.delete(SEWRInterceptor.BypassSewrProxy),
                withCredentials: true
            });
            return next.handle(sewrBypassedRequest);
        }

        // Proxy all other requests through sewr
        const sewrRoutedRequest = request.clone({
            url: `${environment.sewr}/pipe${request.url}`,
            withCredentials: true
        });
        return next.handle(sewrRoutedRequest);
    }
}
