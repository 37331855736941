<div class="sk-page-block m-100 px-100">
    <h3 class="sk-title-block mt-100 mb-200" translate="COMPONENTS.BRAND_ASSETS.TITLE"></h3>
    <div class="col-lg-12 p-0 mb-150">
        <span translate="COMPONENTS.BRAND_ASSETS.CUSTOMIZE_TITLE"></span>
    </div>
    <form [formGroup]="skBrandAssetsForm" novalidate class="col-md-12" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-3 p-0 mb-150">
                <label translate="COMPONENTS.BRAND_ASSETS.COMPANY_NAME" class="font-medium"></label>
                <input class="form-control" id="skWhiteLabelSettingsCompanyNameInput" formControlName="skCompanyName"
                    name="skCompanyName" type="text" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 p-0">
                <div class="row m-0">
                    <div class="col-md-8 p-0 mr-4">
                        <label translate="COMPONENTS.BRAND_ASSETS.FAVICON" class="font-medium"></label>&nbsp;
                        <span translate="COMMON.OPTIONAL" class="text-muted"></span>
                    </div>
                    <div class="col-md-2 p-0 ml-3" *ngIf="skFaviconUrl">
                        <label translate="COMPONENTS.BRAND_ASSETS.PREVIEW" class="font-medium"></label>&nbsp;
                    </div>
                    <div class="col-md-8 p-0 mr-4">
                        <div class="custom-file">
                            <input class="form-control" id="skWhiteLabelSettingsFavIconInput"
                                formControlName="skPageFavicon" name="skPageFavicon" type="file"
                                (change)="pageFaviconFileChange($event.target.files)" />
                            <label class="custom-file-label" for="skWhiteLabelSettingsFavIconInput">
                                <div class="custom-file-name text-truncate">
                                    {{ (pageFaviconFileName || previewFaviconFileName || 'COMMON.CHOOSE_FILE') | translate }}
                                </div>
                            </label>
                            <small class="text-muted" translate="COMPONENTS.BRAND_ASSETS.FAVICON_HELP_TEXT"></small>
                        </div>
                    </div>
                    <div class="col-md-2 p-0 ml-3" *ngIf="skFaviconUrl">
                        <img [src]="skFaviconUrl" height="35" width="auto" class="responsive border">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="skBrandAssetsForm.errors && customValidationMessage !== ''" class="row">
            <div class="col-lg-3 p-2  alert alert-danger">
                {{customValidationMessage | translate}}
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 mt-100 p-0">
                <hr>
                <button class="btn btn-primary sk-has-spinner sk-abs-spinner mr-3" [ngClass]="(isLoading)? 'active': ''"
                    [disabled]="skBrandAssetsForm.invalid || skBrandAssetsForm.pristine || isLoading">
                    <div class="sk-btn-spinner">
                        <div class="sk-spin-loader loader-dark"></div>
                    </div><span class="sk-loader-label">{{ 'COMMON.SAVE' | translate }}</span>
                </button>
            </div>
        </div>
    </form>
</div>
