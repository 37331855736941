import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService, SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { BetaFlag } from '../../../core/infrastructure/beta-flags';
import { BrandSettingsService } from '../settings/settings.service';
import { CustomUrlComponent } from '../shared/custom-url/custom-url.component';
import { CustomerSupportComponent } from '../shared/customer-support/customer-support.component';
import { MarketplaceConfigFormModel } from './marketplace-config-form.model';
import { MarketplaceConfigPreviewDialogComponent } from './marketplace-config-preview-dialog/marketplace-config-preview-dialog.component';
import { MarketplaceConfigService } from './marketplace-config.service';

@Component({
    selector: 'sk-marketplace-config',
    templateUrl: './marketplace-config.component.html',
    styleUrls: ['./marketplace-config.component.scss'],
    providers: [
        MarketplaceConfigService,
        SkyKickModalService
    ]
})
export class MarketplaceConfigComponent extends SkyKickProductPage implements OnInit, AfterViewInit {
    /**
     * The form parent form for the component and all subcomponents. Initialized within ngOnInit.
     */
    public skMarketplaceConfigForm!: FormGroup;
    public skShowRequestAccessPage: boolean;
    /**
     * Returns true if the user has never saved whitelabel marketplace config data to the server.
     */
    public skFirstSave: boolean;
    public skPublished: boolean;
    public isLoading: boolean;
    public get skPageTitle(): string | null {
        const skPageTitle = this.skMarketplaceConfigForm.get('skPageTitle') as FormControl | null;
        if (!skPageTitle) { return null; }
        return skPageTitle.value as string;
    }
    /**
     * The reference to the child component, CustomUrlComponent. Initialized by Angular
     */
    @ViewChild(CustomUrlComponent) public skCustomUrl!: CustomUrlComponent;
    /**
     * The reference to the child component, CustomUrlComponent. Initialized by Angular
     */
    @ViewChild(CustomerSupportComponent) public skCustomerSupport!: CustomerSupportComponent;

    constructor(
        taskManagerService: TaskManagerService,
        activatedRoute: ActivatedRoute,
        readonly userService: AbstractUserProvider,
        private readonly formBuilder: FormBuilder,
        private readonly toastrService: ToastrService,
        private readonly modalService: SkyKickModalService,
        private readonly marketplaceConfigService: MarketplaceConfigService,
        private readonly brandSettingsService: BrandSettingsService,
        private readonly translateService: TranslateService
    ) {
        super(taskManagerService);
        this.isLoading = true;
        this.skFirstSave = true;
        this.skPublished = false;
        const currentUser: ISkyKickPartnerPortalUser = userService.getCurrentUser();
        this.skShowRequestAccessPage = !currentUser.hasPermission(BetaFlag.MarketplaceSyndication);
    }

    async ngOnInit(): Promise<void> {
        // Construct the form.
        this.skMarketplaceConfigForm = this.formBuilder.group({
            skPageTitle: ['', [Validators.required]],
            skCustomUrl: this.formBuilder.group({
                skCustomUrlSelection: this.formBuilder.control('subdomain', [Validators.required]),
                skSubDomainName: this.formBuilder.control(''),
                skDomainName: this.formBuilder.control(''),
                skCertificate: this.formBuilder.control(''),
                skSelectedSslCert: this.formBuilder.control(''),
                skSslPassphrase: this.formBuilder.control('')
            }),
            skCustomerSupport: this.formBuilder.group({
                skSupportOptions: this.formBuilder.control('support-details', [Validators.required]),
                // by default we require support-details
                skSupportEmail: this.formBuilder.control('', [Validators.required]),
                skSupportPhone: this.formBuilder.control('', [Validators.required]),
                skSupportHours: this.formBuilder.control('', [Validators.required]),
                skSupportUrl: this.formBuilder.control('')
            }),
            skGrantAccessPermission: [false, [Validators.required]]
        });

    }

    async ngAfterViewInit(): Promise<void> {
        // Initialize Child Form Groups with data if available.
        try {
            if (this.skShowRequestAccessPage) { return; }
            this.isLoading = true;
            const marketplaceConfigSettings = await this.marketplaceConfigService.getMarketplaceConfigWhiteLabelSettings();
            if (!marketplaceConfigSettings) {
                this.skFirstSave = true;
                this.skPublished = false;
                return;
            }

            this.skFirstSave = false;
            this.skPublished = marketplaceConfigSettings.skIsEnabled || false;

            this.skMarketplaceConfigForm.patchValue(marketplaceConfigSettings);
            (this.skMarketplaceConfigForm.get('skCustomUrl.skCustomUrlSelection') as FormControl).updateValueAndValidity();
        } catch (err) {
            console.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    async onSubmit(publish: boolean): Promise<void> {
        this.validateRequirements();
        if (this.skMarketplaceConfigForm.invalid) { return; }

        const marketPlaceConfigFormData: MarketplaceConfigFormModel = this.skMarketplaceConfigForm.getRawValue();
        try {
            this.isLoading = true;
            await this.marketplaceConfigService.saveMarketplaceConfigWhiteLabelSettings(marketPlaceConfigFormData, publish);
            this.skPublished = publish;
            this.skFirstSave = false;

            const successTitle = await this.translateService.get('COMMON.SUCCESS').toPromise<string>();
            const successMessage = await this.translateService.get('COMMON.SAVED_SETTINGS').toPromise<string>();
            this.toastrService.success(successMessage, successTitle);
        } catch (ex) {
            const failureTitle = await this.translateService.get('COMMON.FAILURE').toPromise<string>();
            const failureMessage = await this.translateService.get('ERRORS.ERROR_SAVED_SETTINGS').toPromise<string>();
            let toastMessage: string = failureMessage;

            // Check if the exception is an HttpErrorResponse.
            if (!(ex instanceof HttpErrorResponse)) {
                this.toastrService.error(toastMessage, failureTitle);
                return;
            }

            const certificateNotValidStatusCode = 460;
            const customUriInUseStatusCode = 461;
            const skCustomUrlFormGroup: FormGroup = this.skMarketplaceConfigForm.controls['skCustomUrl'] as FormGroup;
            const errors: ValidationErrors = { incorrect: true };
            if (ex.status === customUriInUseStatusCode) {
                const skDomainNameControl: AbstractControl = skCustomUrlFormGroup.controls['skDomainName'];
                const skSubDomainNameControl: AbstractControl = skCustomUrlFormGroup.controls['skSubDomainName'];

                // Determine domain option so that we can mark the correct FormControl as incorrect
                if (this.skCustomUrl.isSubDomainOption) {
                    skDomainNameControl.setErrors(errors);
                } else {
                    skSubDomainNameControl.setErrors(errors);
                }
            } else if (ex.status === certificateNotValidStatusCode) {
                const skSslPassphrase: AbstractControl = skCustomUrlFormGroup.controls['skSslPassphrase'];
                skSslPassphrase.setErrors(errors);
            }

            // Check to see if the exception has an error property and that the error property is a string.
            const hasErrorMessage: boolean = !!ex.error && typeof (ex.error) === 'string';
            toastMessage = hasErrorMessage ? ex.error as string : toastMessage;

            this.toastrService.error(toastMessage, failureTitle);
        } finally {
            this.isLoading = false;
        }
    }

    async onPreview(): Promise<void> {
        try {
            this.isLoading = true;
            this.validateRequirements();
            const marketPlaceConfigFormData: MarketplaceConfigFormModel = this.skMarketplaceConfigForm.getRawValue();
            const brandSettings = await this.brandSettingsService.getBrandSettings();
            this.isLoading = false;

            // Constructs then opens the modal view
            const skykickModal = this.modalService
                .open<MarketplaceConfigPreviewDialogComponent, boolean>(
                    MarketplaceConfigPreviewDialogComponent,
                    { size: 'xl' });

            // Note: NgbModalOptions doesn't let us pass init data, therefore we have to pass data using the componentInstance reference.
            const marketplaceConfigPreviewDialog: MarketplaceConfigPreviewDialogComponent = skykickModal.componentInstance;
            const customerSupportInfo = marketPlaceConfigFormData.skCustomerSupport;
            if (brandSettings) {
                marketplaceConfigPreviewDialog.skCompanyName = brandSettings.companyName;
                marketplaceConfigPreviewDialog.skLogoUrl = brandSettings.companyLogoUri;
            }
            marketplaceConfigPreviewDialog.skPublished = this.skPublished;
            marketplaceConfigPreviewDialog.skSupportOption = customerSupportInfo.skSupportOptions;
            if (customerSupportInfo.skSupportOptions === 'support-details') {
                marketplaceConfigPreviewDialog.skCustomerSupportInfo = {
                    emailAddress: customerSupportInfo.skSupportEmail,
                    hours: customerSupportInfo.skSupportHours,
                    phoneNumber: customerSupportInfo.skSupportPhone
                };
            } else {
                marketplaceConfigPreviewDialog.skCustomerSupportInfo = customerSupportInfo.skSupportUrl;
            }

            // Publish changes if the user clicked the publish button.
            await skykickModal.result.then(async result => {
                const publish = result.data;
                return publish ? this.onSubmit(true) : Promise.resolve();
            });
        } finally {
            this.isLoading = false;
        }
    }

    private validateRequirements(): void {
        // Force the control and all child controls to recheck themselves for validity.
        (this.skMarketplaceConfigForm.get('skCustomUrl.skCustomUrlSelection') as FormControl).updateValueAndValidity();
        const skPageTitle = this.skMarketplaceConfigForm.get('skPageTitle') as FormControl | null;
        if (skPageTitle) { skPageTitle.markAsDirty(); }
        this.skCustomUrl.validateRequirements();
        this.skCustomerSupport.validateRequirements();
    }
}
