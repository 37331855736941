import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService, SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { BetaFlag } from '../../../core/infrastructure/beta-flags';
import { BrandSettingsService } from '../settings/settings.service';
import { CustomUrlComponent } from '../shared/custom-url/custom-url.component';
import { CustomerSupportComponent } from '../shared/customer-support/customer-support.component';
import { CloudBackupFormModel } from './cloud-backup-form.model';
import { CloudBackupPreviewDialogComponent } from './cloud-backup-preview-dialog/cloud-backup-preview-dialog.component';
import { CloudBackupService } from './cloud-backup.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'main[sk-dashboard]',
    templateUrl: './cloud-backup.component.html',
    styleUrls: ['./cloud-backup.component.scss'],
    providers: [
        CloudBackupService,
        SkyKickModalService
    ]
})
export class CloudBackupComponent extends SkyKickProductPage implements OnInit, AfterViewInit {
    /**
     * The form parent form for the component and all subcomponents. Initialized within ngOnInit.
     */
    public skCloudBackupForm!: FormGroup;
    /**
     * Returns true if the user has never saved whitelabel cloudbackup data to the server.
     */
    public skFirstSave: boolean;
    public skPublished: boolean;
    public isLoading: boolean;
    public get skPageTitle(): string | null {
        const skPageTitle = this.skCloudBackupForm.get('skPageTitle') as FormControl | null;
        if (!skPageTitle) { return null; }
        return skPageTitle.value as string;
    }
    /**
     * The reference to the child component, CustomUrlComponent. Initialized by Angular
     */
    @ViewChild(CustomUrlComponent) public skCustomUrl!: CustomUrlComponent;
    /**
     * The reference to the child component, CustomUrlComponent. Initialized by Angular
     */
    @ViewChild(CustomerSupportComponent) public skCustomerSupport!: CustomerSupportComponent;

    constructor(
        taskManagerService: TaskManagerService,
        activatedRoute: ActivatedRoute,
        readonly userService: AbstractUserProvider,
        private readonly formBuilder: FormBuilder,
        private readonly cloudBackupService: CloudBackupService,
        private readonly brandSettingsService: BrandSettingsService,
        private readonly toastrService: ToastrService,
        private readonly modalService: SkyKickModalService,
        private readonly translateService: TranslateService,
        private readonly sanitizer: DomSanitizer
    ) {
        super(taskManagerService);
        this.isLoading = true;
        this.skFirstSave = true;
        this.skPublished = false;
        const currentUser: ISkyKickPartnerPortalUser = userService.getCurrentUser();
    }

    ngOnInit(): void {
        // Construct the form.
        this.skCloudBackupForm = this.formBuilder.group({
            skPageTitle: ['', [Validators.required]],
            skCustomUrl: this.formBuilder.group({
                skCustomUrlSelection: this.formBuilder.control('subdomain', [Validators.required]),
                skSubDomainName: this.formBuilder.control(''),
                skDomainName: this.formBuilder.control(''),
                skCertificate: this.formBuilder.control(''),
                skSelectedSslCert: this.formBuilder.control(''),
                skSslPassphrase: this.formBuilder.control('')
            }),
            skCustomerSupport: this.formBuilder.group({
                skSupportOptions: this.formBuilder.control('support-details', [Validators.required]),
                // by default we require support-details
                skSupportEmail: this.formBuilder.control('', [Validators.required]),
                skSupportPhone: this.formBuilder.control('', [Validators.required]),
                skSupportHours: this.formBuilder.control('', [Validators.required]),
                skSupportUrl: this.formBuilder.control('')
            }),
            skLearnMoreUrl: this.formBuilder.control('', [
                Validators.pattern(/^https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}\/?/)
            ])
        });
    }

    async ngAfterViewInit(): Promise<void> {
        // Initialize Child Form Groups with data if available.
        try {
            this.isLoading = true;
            const backupSettings = await this.cloudBackupService.getBackupWhiteLabelSettings();
            if (!backupSettings) {
                this.skFirstSave = true;
                this.skPublished = false;
                return;
            }

            this.skFirstSave = false;
            this.skPublished = backupSettings.skIsEnabled || false;

            this.skCloudBackupForm.patchValue(backupSettings);
            (this.skCloudBackupForm.get('skCustomUrl.skCustomUrlSelection') as FormControl).updateValueAndValidity();
        } catch (err) {
            console.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    async onSubmit(publish: boolean): Promise<void> {
        this.validateRequirements();
        if (this.skCloudBackupForm.invalid) { return; }

        const cloudBackupFormData: CloudBackupFormModel = this.skCloudBackupForm.getRawValue();
        try {
            this.isLoading = true;
            await this.cloudBackupService.saveBackupWhiteLabelSettings(cloudBackupFormData, publish);
            this.skPublished = publish;
            this.skFirstSave = false;

            const successTitle = await this.translateService.get('COMMON.SUCCESS').toPromise<string>();
            const successMessage = await this.translateService.get('COMMON.SAVED_SETTINGS').toPromise<string>();
            this.toastrService.success(successMessage, successTitle);
        } catch (ex) {
            const failureTitle = await this.translateService.get('COMMON.FAILURE').toPromise<string>();
            const failureMessage = await this.translateService.get('ERRORS.ERROR_SAVED_SETTINGS').toPromise<string>();
            let toastMessage: string = failureMessage;

            // Check if the exception is an HttpErrorResponse.
            if (!(ex instanceof HttpErrorResponse)) {
                this.toastrService.error(toastMessage, failureTitle);
                return;
            }

            const certificateNotValidStatusCode = 460;
            const customUriInUseStatusCode = 461;
            const skCustomUrlFormGroup: FormGroup = this.skCloudBackupForm.controls['skCustomUrl'] as FormGroup;
            const errors: ValidationErrors = { incorrect: true };
            if (ex.status === customUriInUseStatusCode) {
                const skDomainNameControl: AbstractControl = skCustomUrlFormGroup.controls['skDomainName'];
                const skSubDomainNameControl: AbstractControl = skCustomUrlFormGroup.controls['skSubDomainName'];

                // Determine domain option so that we can mark the correct FormControl as incorrect
                if (this.skCustomUrl.isSubDomainOption) {
                    skDomainNameControl.setErrors(errors);
                } else {
                    skSubDomainNameControl.setErrors(errors);
                }
            } else if (ex.status === certificateNotValidStatusCode) {
                const skSslPassphrase: AbstractControl = skCustomUrlFormGroup.controls['skSslPassphrase'];
                skSslPassphrase.setErrors(errors);
            }

            // Check to see if the exception has an error property and that the error property is a string.
            const hasErrorMessage: boolean = !!ex.error && typeof (ex.error) === 'string';
            toastMessage = hasErrorMessage ? ex.error as string : toastMessage;

            this.toastrService.error(toastMessage, failureTitle);
        } finally {
            this.isLoading = false;
        }
    }

    async onPreview(): Promise<void> {
        try {
            this.isLoading = true;
            this.validateRequirements();
            const cloudBackupFormData: CloudBackupFormModel = this.skCloudBackupForm.getRawValue();
            const brandSettings = await this.brandSettingsService.getBrandSettings();
            this.isLoading = false;

            // Constructs then opens the modal view.
            const skykickModal = this.modalService
                .open<CloudBackupPreviewDialogComponent, boolean>(
                    CloudBackupPreviewDialogComponent,
                    { size: 'xl' });

            // Note: NgbModalOptions doesn't let us pass init data, therefore we have to pass data using the componentInstance reference.
            const cloudBackPreviewDialog: CloudBackupPreviewDialogComponent = skykickModal.componentInstance;
            const customerSupportInfo = cloudBackupFormData.skCustomerSupport;
            if (brandSettings) {
                cloudBackPreviewDialog.skCompanyName = brandSettings.companyName;
                cloudBackPreviewDialog.skLogoUrl = brandSettings.companyLogoUri;
            }
            cloudBackPreviewDialog.skPublished = this.skPublished;
            cloudBackPreviewDialog.skSupportOption = customerSupportInfo.skSupportOptions;
            if (customerSupportInfo.skSupportOptions === 'support-details') {
                cloudBackPreviewDialog.skCustomerSupportInfo = {
                    emailAddress: customerSupportInfo.skSupportEmail,
                    hours: customerSupportInfo.skSupportHours,
                    phoneNumber: customerSupportInfo.skSupportPhone
                };
            } else {
                cloudBackPreviewDialog.skCustomerSupportInfo = customerSupportInfo.skSupportUrl;
            }

            // Publish changes if the user clicked the publish button.
            await skykickModal.result.then(async result => {
                const publish = result.data;
                return publish ? this.onSubmit(true) : Promise.resolve();
            });
        } finally {
            this.isLoading = false;
        }
    }

    private validateRequirements(): void {
        // Force the control and all child controls to recheck themselves for validity.
        (this.skCloudBackupForm.get('skCustomUrl.skCustomUrlSelection') as FormControl).updateValueAndValidity();
        const skPageTitle = this.skCloudBackupForm.get('skPageTitle') as FormControl | null;
        const skLearnMoreUrl = this.skCloudBackupForm.get('skLearnMoreUrl') as FormControl | null;
        if (skPageTitle) { skPageTitle.markAsDirty(); }
        if (skLearnMoreUrl) {
            const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, skLearnMoreUrl.value) || '';
            skLearnMoreUrl.setValue(sanitizedUrl);
            skLearnMoreUrl.updateValueAndValidity();
        }
        this.skCustomUrl.validateRequirements();
        this.skCustomerSupport.validateRequirements();
    }
}
